import * as React from "react"
import { graphql } from 'gatsby';
import parse from 'html-react-parser';

import Layout from 'components/layout';
import Link from 'components/link';
import Mission from 'components/mission';
import ReadTime from 'components/readTime';
import Seo from 'components/seo';

import * as style from 'styles/post-detail.module.scss';

const EventPage = (props) => {
	let { allEventsEvent: {edges: events} } = props.data;
	events = events.map(item => item.node);
	if (events.length === 0)return null;
	const event = events[0];
	if (!!event.body === false)return null;
	return (
		<Layout>
			<Seo title={event.title} description={event.intro} />
			<div className="grid-container">
				<div className="grid-x grid-margin-x">
					<div className="small-12 cell">
						<section className={style.container}>
							<div className="grid-x">
								<div className="small-10 small-offset-1 medium-offset-1 medium-6 cell end">
									<h1 className={style.title}>{ event.title }</h1>
									<div className={style.topic}><ReadTime className={style.readTime} data={event.body} /></div>
								</div>
							</div>
							<div className="grid-x">
								<div className="small-10 small-offset-1 cell end">
									<div className={style.body}>{ parse(event.body) }</div>
								</div>
							</div>
						</section>
					</div>
				</div>
			</div>
			<Mission />
		</Layout>
	)
}

export default EventPage

export const query = graphql`
	query ($permalink:String) {
	  allEventsEvent(filter: {permalink: {eq: $permalink}, deleted: {eq: false}, archived: {eq: false}, approved: {eq: true}}) {
	    edges {
	      node {
	        title
	        intro
	        body
	      }
	    }
	  }
  	}
`